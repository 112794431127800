<template>
  <form class="pa-6">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="9"
        class="d-flex flex-column pr-sm-1"
      >
        <v-radio-group
          :value="formData.subscriptionType"
          class="mt-0 border-thin mb-1"
          hide-details
          @change="onSubscriptionTypeChange"
        >
          <div class="d-flex justify-space-around pa-4 flex-column flex-sm-row">
            <div class="d-flex flex-row flex-sm-column mb-2 mb-sm-0">
              <p class="order-last order-sm-first mb-0 mb-sm-2">
                {{ $t('productDetails.monthlySubscription') }}
              </p>
              <v-radio
                class="justify-center"
                data-cy="change-monthly"
                value="monthly"
              />
            </div>
            <div class="d-flex flex-row flex-sm-column">
              <p class="order-last order-sm-first mb-0 mb-sm-2">
                {{ $t('productDetails.yearlySubscription') }}
              </p>
              <v-radio
                class="justify-center"
                data-cy="change-yearly"
                value="yearly"
              />
            </div>
          </div>
        </v-radio-group>

        <div class="border-thin flex-grow-1">
          <material-product-details-change-plan-max-users-count-slider
            :initial-value="product.maxUsersCount"
            :type="TYPE_CLOUD"
            @change="updateUsersCount"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="text-center pt-1 pt-sm-0"
      >
        <material-product-details-change-plan-request-quotation-card />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="pa-0"
      >
        <material-product-details-change-plan-bundles-card
          v-if="product.bundlesOnly || product.pricingType === PRICING_TYPE_MIX"
          :bundle-id="formData.bundleId"
          @bundle-change="updateBundleId"
        />
        <material-product-details-change-plan-plugins-card
          v-if="!product.bundlesOnly || product.pricingType === PRICING_TYPE_MIX"
          :selected-plugins="formData.plugins"
          :show-additional-plugins-only="product.pricingType === PRICING_TYPE_MIX"
          @plugin-change="onPluginChange"
          @bundle-change="onPluginBundleChange"
        />

        <div class="mx-n6 my-6">
          <v-divider />
        </div>
        <material-product-details-change-plan-order-summary :form-data="formData" />
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { isEqual } from 'lodash'
import { mapActions } from 'vuex'
import { PRICING_TYPE_MIX, TYPE_CLOUD } from '@/constants/app'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      watcherStarted: false,
      formData: this.getDefaultFormData(),
      PRICING_TYPE_MIX,
      TYPE_CLOUD
    }
  },
  watch: {
    formData: {
      handler: function (val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.getSummaryPrice()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getSummaryPrice()
  },
  methods: {
    ...mapActions('productDetails', ['getOrderPrice']),
    getSummaryPrice () {
      this.getOrderPrice(this.formData)
    },
    getDefaultFormData () {
      return {
        ...this.product,
        clientId: this.$route.params.clientId
      }
    },
    onSubscriptionTypeChange (subscriptionType) {
      this.formData = { ...this.formData, subscriptionType }
    },
    updateUsersCount (maxUsersCount) {
      this.formData = { ...this.formData, maxUsersCount }
    },
    updateBundleId (bundleId) {
      this.formData = { ...this.formData, bundleId }
    },
    onPluginChange ({ plugin, checked }) {
      this.formData = {
        ...this.formData,
        plugins: checked
          ? [...this.formData.plugins, plugin]
          : this.formData.plugins.filter(plug => plug.easy_price_book_product_id !== plugin.easy_price_book_product_id)
      }
    },
    onPluginBundleChange ({ bundle: { plugins }, checked }) {
      const pluginIds = plugins.map(p => p.easy_price_book_product_id)

      this.formData = {
        ...this.formData,
        plugins: checked
          ? [...this.formData.plugins, ...plugins]
          : this.formData.plugins.filter(plug => !pluginIds.includes(plug.easy_price_book_product_id))
      }
    },
    onBundleChange (bundleId) {
      this.formData = { ...this.formData, bundleId }
    }
  }
}
</script>
